import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import PageContents from "../components/Reviews/PageContents"

const ReviewsPage = (props) => {
  const { data, location } = props
  const path = location.pathname
  const { page, reviews, stampedReviews, ratingSummary } = data
  const { metaTitle, metaDescription } = page

  return (
    <Layout>
      <Seo title={metaTitle} description={metaDescription} path={path} />
      <PageContents blocks={page.blocks}
                    reviews={reviews.nodes}
                    ratingSummary={ratingSummary}
                    stampedReviews={stampedReviews.edges}/>
    </Layout>
  )
}

export default ReviewsPage

export const pageQuery = graphql`
  query ReviewsPageQuery {
    page: contentfulPage(slug: { eq: "reviews" }) {
      id
      metaTitle
      metaDescription
      blocks {
        __typename
        ... on ContentfulHeroBlock {
          id
          header
          subText {
            raw
          }
          ctaLabel
          ctaLink
          hero {
            gatsbyImageData(width: 1920)
          }
          mobileHero {
            gatsbyImageData(height: 808)
          }
        }
        ... on ContentfulProductReview {
          name
          username
          age
          date(formatString: "D/M/YYYY")
          title
          location
          featured
          recommend
          rating
          profileImage {
            gatsbyImageData(width: 146, aspectRatio: 1)
          }
          content {
            raw
          }
        }
        ...expertCarouselFragment
        ...carouselBlockFragment
        ...fullWidthCtaBlockFragment
        ... on ContentfulPress {
          author
          logo {
            file {
              url
            }
          }
          content {
            raw
          }
        }
      }
    }
    ratingSummary: stampedRatingSummary(productId: { eq: "7641585484006" }) {
      id
      badge
      rating
      count
    }
    stampedReviews: allStampedReview(
      filter: {
        review: { productId: { eq: 7641585484006 }, reviewState: { eq: 1 } }
      }
    ) {
      edges {
        node {
          id
          review {
            id
            rating
            title
            productTitle
            body
            author
            dateCreated: dateCreated(formatString: "MM/DD/YYYY")
            date: dateCreated(formatString: "YYYYMMDD")
          }
        }
      }
    }
    reviews: allContentfulProductReview {
      nodes {
        name
        username
        age
        date(formatString: "D/M/YYYY")
        title
        location
        featured
        recommend
        rating
        productSlug
        profileImage {
          gatsbyImageData(width: 146, aspectRatio: 1)
        }
        content {
          raw
        }
      }
    }
  }
`
