import React from "react"
import HeroBlock from "../Core/HeroBlock"
import PressCarousel from "../Core/Press"
import Experts from "../Core/Experts"
import Carousel from "../Core/Carousel/Carousel"
import FullCtaBlock from "./FullCtaBlock"
import ReviewsBlock from "../Core/Reviews/ReviewsBlock"

const PageContents = (props) => {
  const { blocks, reviews, stampedReviews, ratingSummary } = props
  let pageContents = []
  let press = []
  let featuredReviews = []

  blocks.forEach((block, index) => {
    const { __typename: type } = block

    if (type === "ContentfulProductReview") {
      featuredReviews.push(block)
    }
    if (type === "ContentfulHeroBlock") {
      pageContents.push(
        <React.Fragment>
          <HeroBlock {...block} key={index} />
          <ReviewsBlock
            carousel={featuredReviews}
            reviews={reviews}
            stampedReviews={stampedReviews}
            ratingSummary={ratingSummary}
            key={index}
            reviewsPage
          />
        </React.Fragment>
      )
    }
    if (type === "ContentfulExpertCarousel") {
      pageContents.push(<Experts {...block} key={index} />)
    }
    if (type === "ContentfulCarouselBlock") {
      pageContents.push(<Carousel {...block} key={index} />)
    }
    if (type === "ContentfulFullWidthCtaBlock") {
      pageContents.push(<FullCtaBlock {...block} key={index} />)
    }
    if (type === "ContentfulPress") {
      press.push(block)
    }
    if (
      press.length !== 0 &&
      index === blocks.map((el) => el.__typename).lastIndexOf("ContentfulPress")
    ) {
      pageContents.push(<PressCarousel pressList={press} key={index} />)
    }
  })

  return <>{pageContents}</>
}

export default PageContents
