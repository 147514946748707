import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import ContentContainer from "../Core/Container/ContentContainer"
import { ButtonStyle } from "../Core/Buttons/Styles"
import { Link } from "gatsby"

const FullCtaBlockContainer = styled.div`
  margin: 107px auto 136px;
  @media (min-width: ${breakpoints.md}) {
    margin: 155px auto 41px;
  }
`

const StyledImage = styled(GatsbyImage)`
  border-radius: 15px;

  @media (min-width: ${breakpoints.md}) {
    border-radius: 50px;
    max-height: 680px;
  }
`

const MobileImage = styled(StyledImage)`
  display: block;

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`
const DesktopImage = styled(StyledImage)`
  display: none;

  @media (min-width: ${breakpoints.md}) {
    display: block;
  }
`

const BlockContentsContainer = styled.div`
  position: relative;
`

const BlockTextContents = styled.div`
  margin-top: 39px;

  @media (min-width: ${breakpoints.sm}) {
    position: absolute;
    padding: 30px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    margin-top: 0;
  }
`

const Title = styled.div`
  ${fonts.canelaThin};
  color: ${colors.grey};
  font-size: 40px;
  line-height: 1;
  text-align: center;
  @media (min-width: ${breakpoints.sm}) {
    color: ${colors.white};
  }
  @media (min-width: ${breakpoints.xl}) {
    font-size: 72px;
  }
`

const ButtonContainer = styled.div`
  margin-top: 22px;
  min-width: 100%;
  @media (min-width: ${breakpoints.sm}) {
    display: flex;
    justify-content: center;
    margin-top: 18px;
  }
`

const CtaButton = styled(Link)`
  ${ButtonStyle};
  background: ${colors.grey};
  color: ${colors.white};
  min-width: 215px;
  :hover {
    background: ${colors.clay};
  }
`

const FullCtaBlock = (props) => {
  const { background, mobileBackground, title, ctaLabel, ctaLink } = props
  return (
    <FullCtaBlockContainer>
      <ContentContainer>
        <BlockContentsContainer>
          <MobileImage
            image={mobileBackground ? mobileBackground.gatsbyImageData : background.gatsbyImageData}
            alt={title}
          />
          <DesktopImage image={background.gatsbyImageData} alt={title} />
          <BlockTextContents>
            <Title>{title}</Title>
            {ctaLabel && ctaLink && (
              <ButtonContainer>
                <CtaButton to={ctaLink}>{ctaLabel}</CtaButton>
              </ButtonContainer>
            )}
          </BlockTextContents>
        </BlockContentsContainer>
      </ContentContainer>
    </FullCtaBlockContainer>
  )
}

export default FullCtaBlock
